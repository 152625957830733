<template>
  <div class="container-fluid">
    <div class="row justify-content-center py-3">
      <div class="col-12">
        <next-matchs :lastMatches="matches" :show-details="true" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import NextMatchs from '../../partials/NextMatchs'

export default {
  components: {
    NextMatchs
  },
  data () {
    return {
      matches: []
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'playerFront'
    ])
  },
  mounted() {
    this.$store.dispatch('SET_MENU_PRIMARY_USER', '')
    this.fetchMatches()
  },
  methods: {
    fetchMatches() {
      const lang = this.$route.params.lang
      const cons = this.$route.params.console
      const slug = this.$route.params.playerSlug
      this.path = `${lang}/console/${cons}/player/${slug}/last-matches`
      this.$axios.get(this.path).then((response) => {
        this.matches = response.data.data
      })
    }
  }
}
</script>
